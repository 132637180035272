<template>
  <div class="submit-form">
    <div v-if="!submitted">
      <div class="form-group">
        <label for="nom">Nom</label>
        <input
          type="text"
          class="form-control"
          id="nom"
          required
          v-model="enfant.nom"
          name="nom"
        />
      </div>

      <div class="form-group">
        <label for="dateNaissance">Date de naissance</label>
        <input
          type="date"
          class="form-control"
          id="dateNaissance"
          required
          v-model="enfant.dateNaissance"
          name="dateNaissance"
        />
      </div>

      <div class="form-group">
        <label for="age">Âge</label>
        <input
          type="number"
          class="form-control"
          id="age"
          required
          v-model="enfant.age"
          name="age"
        />
      </div>

      <div class="form-group">
        <label for="genre">Genre</label>
        <input
          type="text"
          class="form-control"
          id="genre"
          required
          v-model="enfant.genre"
          name="genre"
        />
      </div>

      <!-- <div class="form-group">
        <label for="user">Utilisateur</label>
        <input
          class="form-control"
          id="user"
          value="{{currentUser}}"
          required
          v-model="enfant.user"
          name="user"
        />
      </div> -->
      

      <button @click="saveEnfant" class="btn btn-success">Valider</button>
    </div>

    <div v-else>
      <h4>Enfant ajouté avec succès !</h4>
      <button class="btn btn-success" @click="newEnfant">Ajouter</button>
    </div>
  </div>
</template>

<script>
import EnfantDataService from "../services/EnfantDataService";

export default {
  name: "add-enfant",
//   computed: {
//     currentUser() {
//       return this.$store.state.auth.user;
//     }
//   },
  data() {
    return {
      enfant: {
        id: null,
        nom: "",
        dateNaissance: null,
        age: 0,
        genre: "",
        user: null
      },
      submitted: false
    };
  },
  methods: {
    saveEnfant() {
      var data = {
        nom: this.enfant.nom,
        dateNaissance: this.enfant.dateNaissance,
        age: this.enfant.age,
        genre: this.enfant.genre,
        user: this.enfant.user
      };

      EnfantDataService.create(data)
        .then(response => {
          this.enfant.id = response.data.id;
          console.log(response.data);
          this.submitted = true;
        })
        .catch(e => {
          console.log(e);
        });
    },
    
    newEnfant() {
      this.submitted = false;
      this.enfant = {};
    }
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>